import {
  INCIDENT_INFORMATION_CONSTANTS,
  PHYSICIAN_INFORMATION_CONSTANTS,
  SECONDARY_INJURED_BODY_PART_REQUIRED_ERROR,
  SECONDARY_NATURE_OF_INJURY_REQUIRED_ERROR,
} from "src/forms/constants";
import { CUSTOM_VALIDATION_TYPES, FormOverride } from "../../types";
import {
  HELMSMAN_BODY_PART_OPTIONS,
  HELMSMAN_BODY_SIDE_OPTIONS,
  HELMSMAN_CAUSE_OF_INJURY_OPTIONS,
  HELMSMAN_CONCERNS_OPTIONS,
  HELMSMAN_NATURE_OF_INJURY,
} from "src/forms/options";
import { INJURED_BODY_PART_NATURE_OF_INJURY_VALID_COMBINATIONS } from "../validations/constants";

export const helmsmanFormOverride: FormOverride = {
  state: {
    [INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1]: {
      hidden: true,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
    [INCIDENT_INFORMATION_CONSTANTS.ADD_BODY_PART_NAME]: {
      hidden: false,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
      hidden: true,
      ignoreInFinalForm: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
      hidden: false,
      ignoreInFinalForm: false,
    },
  },
  //TODO: Setup the config for the Helmsman field when we add the option lists
  config: {
    [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
      options: HELMSMAN_NATURE_OF_INJURY,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
      options: HELMSMAN_NATURE_OF_INJURY,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      options: HELMSMAN_BODY_PART_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      options: HELMSMAN_BODY_PART_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
      options: HELMSMAN_BODY_SIDE_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_SIDE]: {
      options: HELMSMAN_BODY_SIDE_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_IMPACT]: {
      label: "Cause of Injury",
      options: HELMSMAN_CAUSE_OF_INJURY_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.QUESTIONABLE_CLAIM_REASONS]: {
      options: HELMSMAN_CONCERNS_OPTIONS,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_0]: {
      contained: true,
    },
    [INCIDENT_INFORMATION_CONSTANTS.BODY_PART_INFORMATION_1]: {
      contained: true,
    },
  },
  validation: {
    [INCIDENT_INFORMATION_CONSTANTS.SOURCE_OF_INJURY]: {
      required: { value: true, message: "Source of Injury is required." },
      maxCharacters: {
        value: 15,
        message: "Source of Injury cannot be more than 15 characters long.",
      },
      minCharacters: {
        value: 2,
        message: "Source of Injury cannot be less than 2 characters long.",
      },
    },
    [PHYSICIAN_INFORMATION_CONSTANTS.INITIAL_MEDICAL_TREATMENT]: {
      required: { value: true },
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART]: {
      required: { value: true },
      [CUSTOM_VALIDATION_TYPES.VALID_COMBINED_VALUE]: {
        value: true,
        message:
          "This combination of Body Part and Nature of Injury is not supported, please select different values.",
        joinWith: {
          value: INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY,
          message:
            "This combination of Body Part and Nature of Injury is not supported, please select different values.",
        },
        acceptedValuesList:
          INJURED_BODY_PART_NATURE_OF_INJURY_VALID_COMBINATIONS,
        order: "first",
      },
    },
    [INCIDENT_INFORMATION_CONSTANTS.NATURE_OF_INJURY]: {
      required: { value: true },
      [CUSTOM_VALIDATION_TYPES.VALID_COMBINED_VALUE]: {
        value: true,
        message:
          "This combination of Body Part and Nature of Injury is not supported, please select different values.",
        joinWith: {
          value: INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_BODY_PART,
          message:
            "This combination of Body Part and Nature of Injury is not supported, please select different values.",
        },
        acceptedValuesList:
          INJURED_BODY_PART_NATURE_OF_INJURY_VALID_COMBINATIONS,
        order: "second",
      },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART]: {
      required: {
        value: true,
        message: SECONDARY_INJURED_BODY_PART_REQUIRED_ERROR,
      },
      [CUSTOM_VALIDATION_TYPES.VALID_COMBINED_VALUE]: {
        value: true,
        message:
          "This combination of Body Part and Nature of Injury is not supported, please select different values.",
        joinWith: {
          value: INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY,
          message:
            "This combination of Body Part and Nature of Injury is not supported, please select different values.",
        },
        acceptedValuesList:
          INJURED_BODY_PART_NATURE_OF_INJURY_VALID_COMBINATIONS,
        order: "first",
      },
    },
    [INCIDENT_INFORMATION_CONSTANTS.SECONDARY_NATURE_OF_INJURY]: {
      required: {
        value: true,
        message: SECONDARY_NATURE_OF_INJURY_REQUIRED_ERROR,
      },
      [CUSTOM_VALIDATION_TYPES.VALID_COMBINED_VALUE]: {
        value: true,
        message:
          "This combination of Body Part and Nature of Injury is not supported, please select different values.",
        joinWith: {
          value: INCIDENT_INFORMATION_CONSTANTS.SECONDARY_INJURED_BODY_PART,
          message:
            "This combination of Body Part and Nature of Injury is not supported, please select different values.",
        },
        acceptedValuesList:
          INJURED_BODY_PART_NATURE_OF_INJURY_VALID_COMBINATIONS,
        order: "second",
      },
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_INJURED_SIDE]: {
      required: { value: true },
    },
    [INCIDENT_INFORMATION_CONSTANTS.PRIMARY_IMPACT]: {
      required: { value: false },
    },
  },
};
